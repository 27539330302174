



























import {Vue, Component, Prop} from 'vue-property-decorator'
import Confirmacion from '@/components/Confirmacion.vue'
import TablaReportes from '@/components/TablaReportes.vue'
import DetalleRepDespachoAlmacigueras from '@/components/Reportes/DetalleRepDespachoAlmacigueras.vue'
import { reporteriaAxios } from '@/plugins/axios';
import ResponseCustom from '@/entidades/ResponseCustom';
import { mensajes } from '@/plugins/notify'
@Component({
    components:{
        Confirmacion,
        TablaReportes,
        DetalleRepDespachoAlmacigueras
    }
})

export default class extends Vue
{
    componentName='Confirmacion'
    modal=false;
    modal2=false;
    desde='';
    hasta='';
    desdeModal=false;
    hastaModal=false;
    headers:any[]= 
    [
        {
          text: 'Fecha',
          align: 'start',
          sortable: false,
          value: 'fecha'
        },
        {
          text: 'Usuario',
          align: 'end',
          sortable: false,
          value: 'usuario'
        },
        {
          text: 'Almacigueras enviadas',
          align: 'end',
          sortable: false,
          value: 'almacigueraEnviada'
        },
        {
          text: 'Almacigueras recibidas',
          align: 'end',
          sortable: false,
          value: 'almacigueraRecibida'
        },
        {
          text: 'Plantulas enviadas',
          align: 'end',
          sortable: false,
          value: 'plantulaEnviada'
        },
        {
          text: 'Plantulas recibidas',
          align: 'end',
          sortable: false,
          value: 'plantulaRecibida',
        },
        { text: 'Acciones', align: 'center', value: 'acciones', sortable: false },        
    ];
    cabeceraDetalles:any[]=[
        {
          text: 'Lote',
          align: 'start',
          sortable: false,
          value: 'loteName'
        },
        {
          text: 'Hacienda',
          align: 'start',
          sortable: false,
          value: 'haciendaName'
        },
        {
          text: 'Plantulas',
          align: 'end',
          sortable: false,
          value: 'plantula'
        },
        {
          text: 'Almacigueras',
          align: 'end',
          sortable: false,
          value: 'almaciguera'
        },
    ]
    items=new Array();
    detalles=new Array();
    
    async cargarDatos(fechas:any){
        mensajes.advertencia('Cargando datos, por favor espere...')
        await reporteriaAxios({
            url:`RecepcionPlantula/ObtenerCabecera`,
            method:'POST',
            data:fechas
        }).then((data)=>{
            var resp:ResponseCustom<any>=data.data;
            console.log("Respuesta; ", resp)
            if(resp.isSuccess){
                mensajes.exito('Datos cargados correctamente');
                this.items=resp.isResult;
            }else{
                mensajes.error('Error: '+ resp.isMessage);
            }
        }).catch((err)=>{
            mensajes.errorDeConexion();
        })
    }
    async obtenerPDF(item:any){
        console.log('Llamdo en el padre con: ', item)
        mensajes.advertencia('Generando pdf...')
        await reporteriaAxios({
            url:`RecepcionPlantula/GenerarPdf/${item.id}`,
            method:'get',
            responseType: 'blob'
        }).then((data)=>{
            mensajes.exito('Documento generado correctamente')
            const url = window.URL
                .createObjectURL(new Blob([data.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'recepcion'+this.generarNombre(item.id)+'.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch((err)=>{
            mensajes.errorDeConexion();
        })
    }
    generarNombre(id:number){
        var limite=9;
        var cantidadDigitosId=id.toString().length;
        var restantes=limite-cantidadDigitosId;
        var salida='';
        for (let index = 0; index < restantes; index++) {
            salida=salida+'0';
        }
        return salida+id.toString();
    }
    async verDetalles(item:any){
        mensajes.advertencia('Cargando detalles...')
        console.log('Llamdo en el padre con: ', item)
        await reporteriaAxios({
            url:`RecepcionPlantula/ObtenerDetalleId/${item.id}`,
            method:'get',
        }).then((data)=>{
            var resp:ResponseCustom<any>=data.data;
            console.log("Respuesta; ", resp)
            if(resp.isSuccess){
                this.detalles=resp.isResult;
                this.modal=true;
                this.componentName='DetalleRepDespachoAlmacigueras'
            }else{
                mensajes.error('Error: '+ resp.isMessage);
            }
        }).catch((err)=>{
            mensajes.errorDeConexion();
        })
    }
}

